import React from "react";

function BrandingBadge() {
  return (
    <span>
      <h4>spread</h4>
    </span>
  );
}

export default BrandingBadge;
